import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";

const ShipmentPage = lazy(() =>
  import("./modules/Shipment-material/pages/shipmentPage")
);

const DeliveryPage = lazy(() =>
  import("./modules/Delivery/pages/DeliveryPage")
);

const BookingPage = lazy(() => import("./modules/Booking/pages/BookingPage"));

const PurchaseOrderPage = lazy(() =>
  import("./modules/Purchase-Order/pages/PurchaseOrderPage")
);

const SupplierPage = lazy(() =>
  import("./modules/Supplier/pages/SupplierPage")
);

const ManifestPage = lazy(() =>
  import("./modules/Manifest/pages/ManifestPage")
);

const QuotesPage = lazy(() => import("./modules/Quotes/pages/QuotesPage"));

const RateSheetPage = lazy(() =>
  import("./modules/Rate-sheets/pages/RatesheetPage")
);

const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const RateSearchPage = lazy(() =>
  import("./modules/RateSearch/RateSearchpage")
);

const LocationsPage = lazy(() =>
  import("./modules/Locations/pages/LocationPage")
);

const DashboardPage = lazy(() => import("./pages/DashboardPage"));

const SchedulePage = lazy(() =>
  import("./modules/Schedules/pages/SchedulePage")
);

const AirRatePage = lazy(() => import("./modules/AirRate/AirRatePage"));

const PoAndStyleManager = lazy(() =>
  import("./modules/PoAndStyleManager/PoAndStyleManager")
);

const AgentPage = lazy(() =>
import("./modules/Agent/AgentPage")
);

const InsurancePage = lazy(() =>
  import("./modules/Insurance/pages/InsurancePage")
  );

const IFramePage = lazy(() => import("./modules/IFrame/IFramePage"));

const ReportPage = lazy(() => import("./modules/Report/reportPage"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <FilterUIProvider>
          <Route path="/dashboard" component={DashboardPage} />
        </FilterUIProvider> */}

        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/shipments" component={ShipmentPage} />
        <Route path="/delivery" component={DeliveryPage} />
        <Route path="/booking" component={BookingPage} />
        <Route path="/purchase-order" component={PurchaseOrderPage} />
        <Route path="/supplier" component={SupplierPage} />
        <Route path="/manifest" component={ManifestPage} />
        <Route path="/quotes" component={QuotesPage} />
        <Route path="/rate-sheet" component={RateSheetPage} />
        <Route path="/user-profile" component={UserProfilePage} />
        <Route path="/rate-search" component={RateSearchPage} />
        <Route path="/locations" component={LocationsPage} />
        <Route path="/agent" component={AgentPage} />
        <Route path="/insurance" component={InsurancePage} />
        <Route path="/schedule" component={SchedulePage} />
        <Route path="/air-rate" component={AirRatePage} />
        <Route path="/report" component={ReportPage} />
        <Route
          path="/booking/purchase-order-and-style-manager/"
          component={PoAndStyleManager}
        />
        <Route
          path="/shipments/purchase-order-and-style-manager/"
          component={PoAndStyleManager}
        />
        <Route path="/iframe/" component={IFramePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
